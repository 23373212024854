import {
  adminProgramBaseUrl,
  programBaseUrl,
  programSurveyRouterUrl,
  programOnboardingUrl,
  programWelcomeUrl,
  programExerciseRouterUrl,
  programPreferenceSelectionUrl,
} from "../urls.js";

import { getNavigationCounts } from "@/programs/services/NavigationService.js";

// For super admin pages, programId is a string (e.g., 'overview' for the page '/admin/programs/overview')
// So we need to check whether it's a number or not
const adminInterface = adminProgramBaseUrl.match(location.pathname);
const programInfo =
  adminInterface && isNaN(adminInterface.programId)
    ? null
    : adminInterface || programBaseUrl.match(location.pathname);
export const navigationModule = {
  namespaced: true,
  state: () => ({
    programId: programInfo ? programInfo.programId : null,
    adminInterface: !!adminInterface,
    participantInterface: !adminInterface,
    navigationCounts: {},
    programOnboardingPages: [
      { pattern: programOnboardingUrl, text: "Onboarding", key: "onboarding" },
      { pattern: programWelcomeUrl, text: "Welcome", key: "welcome" },
      { pattern: programSurveyRouterUrl, text: "Survey", key: "survey" },
      { pattern: programExerciseRouterUrl, text: "Exercise", key: "exercise" },
      {
        pattern: programPreferenceSelectionUrl,
        text: "Prioritization",
        key: "prioritization",
      },
    ],
  }),
  getters: {
    onboardingPages: (state) => {
      const pages = state.programOnboardingPages;
      if (state.programId) {
        const pageInfo = { programId: state.programId };
        return pages.map(function (page) {
          page.url = page.pattern.stringify(pageInfo);
          page.active = page.url === location.pathname;
          return page;
        });
      } else {
        return null;
      }
    },
  },
  mutations: {
    setNavigationCounts(state, counts) {
      state.navigationCounts = counts;
    },
  },
  actions: {
    async loadNavigationCounts({ commit, state }) {
      const counts = await getNavigationCounts(
        state.programId,
        state.adminInterface,
      );
      commit("setNavigationCounts", counts);
    },
  },
};
