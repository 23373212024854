import { library } from "@fortawesome/fontawesome-svg-core";

// ********* Please add new icons alphabetically **********

import { faLinkedin, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faSquare as faSquareRegular } from "@fortawesome/free-regular-svg-icons";
import {
  faAnglesUp,
  faArrowDown,
  faArrowsDownToPeople,
  faArrowLeft,
  faArrowRight,
  faArrowRightFromBracket,
  faArrowRightArrowLeft,
  faArrowsToDot,
  faArrowTrendUp,
  faArrowTurnUp,
  faArrowUp,
  faArrowUpRightDots,
  faAsterisk,
  faAward,
  faBan,
  faBars,
  faBell,
  faBullseye,
  faBullhorn,
  faCalendar,
  faCalendarAlt,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faChartSimple,
  faCheck,
  faCheckSquare,
  faCircle,
  faCircleArrowDown,
  faCircleArrowUp,
  faCircleCheck,
  faCircleXmark,
  faColumns,
  faClock,
  faClose,
  faCloudDownload,
  faCrosshairs,
  faDisplay,
  faDoorOpen,
  faDownload,
  faEdit,
  faEllipsis,
  faEnvelope,
  faEnvelopeOpen,
  faEye,
  faFileAlt,
  faFlag,
  faFilter,
  faGear,
  faGlobe,
  faGripLines,
  faGripVertical,
  faHourglassEnd,
  faHourglassStart,
  faInfo,
  faInfoCircle,
  faLayerGroup,
  faLink,
  faLocationDot,
  faLock,
  faMagnifyingGlass,
  faMedal,
  faPersonCircleQuestion,
  faPieChart,
  faPlay,
  faPlus,
  faRectangleXmark,
  faRepeat,
  faReply,
  faRightFromBracket,
  faRotate,
  faSignal,
  faSignOutAlt,
  faSitemap,
  faSpinner,
  faSquare,
  faSquareCaretLeft,
  faSquareCaretRight,
  faStickyNote,
  faSuitcase,
  faStopwatch,
  faTrash,
  faTriangleExclamation,
  faUpload,
  faUser,
  faUsersGear,
  faVideo,
  faVideoSlash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faAnglesUp,
  faArrowDown,
  faArrowsDownToPeople,
  faArrowLeft,
  faArrowRight,
  faArrowRightFromBracket,
  faArrowRightArrowLeft,
  faArrowsToDot,
  faArrowTrendUp,
  faArrowTurnUp,
  faArrowUp,
  faArrowUpRightDots,
  faAsterisk,
  faAward,
  faBan,
  faBars,
  faBell,
  faBullseye,
  faBullhorn,
  faCalendar,
  faCalendarAlt,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faChartSimple,
  faCheck,
  faCheckSquare,
  faCircle,
  faCircleArrowDown,
  faCircleArrowUp,
  faCircleCheck,
  faCircleXmark,
  faClock,
  faClose,
  faCloudDownload,
  faColumns,
  faCrosshairs,
  faDisplay,
  faDoorOpen,
  faDownload,
  faEdit,
  faEllipsis,
  faEnvelope,
  faEnvelopeOpen,
  faEye,
  faFileAlt,
  faFilter,
  faFlag,
  faGear,
  faGlobe,
  faGripLines,
  faGripVertical,
  faHourglassEnd,
  faHourglassStart,
  faInfo,
  faInfoCircle,
  faLayerGroup,
  faLink,
  faLinkedin,
  faLocationDot,
  faLock,
  faMagnifyingGlass,
  faMedal,
  faPersonCircleQuestion,
  faPieChart,
  faPlay,
  faPlus,
  faRectangleXmark,
  faRepeat,
  faReply,
  faRightFromBracket,
  faRotate,
  faSignal,
  faSignOutAlt,
  faSitemap,
  faSpinner,
  faSquare,
  faSquareRegular,
  faSquareCaretLeft,
  faSquareCaretRight,
  faStickyNote,
  faStopwatch,
  faSuitcase,
  faTrash,
  faTriangleExclamation,
  faUpload,
  faUser,
  faUsersGear,
  faVideo,
  faVideoSlash,
  faXmark,
  faXTwitter,
);

/* add some free styles */

export default library;
