import { createStore } from "vuex";
import axios from "axios";

import debounce from "lodash/debounce";

import {
  adminProgramBaseUrl,
  programBaseUrl,
  programListUrl,
  programDataUrl,
  programCreateUrl,
  programUpdateUrl,
  programUploadImagesUrl,
  programRemoveImagesUrl,
  userBaseUrl,
  programMemberUrls,
  programUsersListUrl,
  programUsersCreateUrl,
  programUsersUpdateUrl,
  programMembersUnpaginatedSearchUrl,
} from "../urls.js";

import { DEFAULT_MAX_COMPETENCIES } from "../utils/constants.js";

import { userModule } from "./user.js";
import { navigationModule } from "./navigation.js";
import { programModule } from "./program.js";
import { competencyModule } from "./competency.js";
import { competencyDetailModule } from "@/programs/stores/competencyDetail.js";
import { levelUpRequestModule } from "./levelUpRequest.js";
import { opportunityDetailModule } from "./opportunityDetail.js";
import { opportunityModule } from "./opportunity.js";
import { opportunityToolbarModule } from "./opportunityToolbar.js";
import { tailoringModule } from "./tailoring.js";
import { placeholderData } from "./placeholderData.js";
import { levelUpRequestsModule } from "./levelUpRequests.js";
import { levelUpRequestDetailsModule } from "./levelUpRequestDetails.js";
import { myReflectionModule } from "./myReflection.js";
import { cohortModule } from "./cohort.js";
import { unlockModule } from "./unlock.js";

import { genericMixin } from "./generic.js";

const DEBOUNCE_MILLIS = 300;

const csrftoken = document.head.querySelector("[name='csrf-token']").attributes
  .content.value;

axios.defaults.headers.common["X-CSRF-Token"] = csrftoken;

const programInfo =
  programBaseUrl.match(location.pathname) ||
  adminProgramBaseUrl.match(location.pathname);

const initialState = {
  programId: programInfo ? programInfo.programId : null,
  programList: [],
  programData: {
    experience_configuration: {},
  },
  programUsers: [],
  programMembers: [],
  programUserData: {},
  programWelcome: "",
  notification: {
    text: "",
    status: "success",
  },
};

export const store = createStore({
  modules: {
    user: userModule,
    navigation: navigationModule,
    program: programModule,
    competency: competencyModule,
    competencyDetail: competencyDetailModule,
    levelUpRequest: levelUpRequestModule,
    opportunityDetail: opportunityDetailModule,
    opportunity: opportunityModule,
    opportunityToolbar: opportunityToolbarModule,
    tailoring: tailoringModule,
    levelUpRequests: levelUpRequestsModule,
    levelUpRequestDetails: levelUpRequestDetailsModule,
    myReflection: myReflectionModule,
    cohort: cohortModule,
    unlock: unlockModule,
  },
  state: {
    ...initialState,
  },
  getters: {
    ...genericMixin.getters,
    programTitle: (state) => {
      return state.programData.name;
    },
    programConfig: (state) => {
      return state.programData.experience_configuration || {};
    },
    programDimensionsEnabled: (state, getters) => {
      // Currently all programs must have dimensions "enabled" since the
      // level-up process is based on advancement at the dimension tier
      // It's possible though for a program to have dimensions "enabled"
      // but really only using competencies (as far as the UI is concerned)
      return getters.programConfig?.has_competency_dimensions || false;
    },
    programOnboardingLevelUpEnabled: (state, getters) => {
      return getters.programConfig
        ? getters.programConfig.initial_levelset
        : false;
    },
    programIconUrl: (state, getters) => {
      return getters.programConfig ? getters.programConfig.icon_url || "" : "";
    },
    programBackgroundUrl: (state, getters) => {
      return getters.programConfig
        ? getters.programConfig.background_url || ""
        : "";
    },
    programDashboardLogoUrl: (state, getters) => {
      return getters.programConfig
        ? getters.programConfig.dashboard_logo_url || ""
        : "";
    },
    programWelcomeHtml: (state) => {
      return state.programData.dashboard_message || state.programWelcome || "";
    },
    programMaxCompetencies: (state) => {
      return (
        state.programData.experience_configuration?.active_competencies_max ||
        DEFAULT_MAX_COMPETENCIES
      );
    },
    userId: () => {
      let pageInfo = userBaseUrl.match(location.pathname);
      return pageInfo ? pageInfo.userId : null;
    },
    experienceConfiguration: (state) => {
      return state.programData.experience_configuration || {};
    },
    aboutPageText: (state, getters) => {
      return getters.experienceConfiguration.about_page_text || "";
    },
    opportunityOverviewIntroText: (state, getters) => {
      return (
        getters.experienceConfiguration.opportunity_overview_intro_text || ""
      );
    },
    competencyOverviewIntroText: (state, getters) => {
      return (
        getters.experienceConfiguration.competency_overview_intro_text || ""
      );
    },
    myAccomplishmentsIntroText: (state, getters) => {
      return (
        getters.experienceConfiguration.my_accomplishments_intro_text || ""
      );
    },
    myLevelUpRequestsIntroText: (state, getters) => {
      return (
        getters.experienceConfiguration.my_level_up_requests_intro_text || ""
      );
    },
    totalStudentCount: (state) => {
      return state.programData.active_student_count;
    },
    members(state) {
      const { data = [] } = state.programMembers;
      return data.map((member) => ({
        ...member,
        url: "",
      }));
    },
    membersPagination(state) {
      return state.programMembers.meta || {};
    },
    eligibleCohortReviewers(state) {
      const ELLIGIBLE_ROLES = ["gsi", "professor"];
      return state.programMembers.filter((member) =>
        ELLIGIBLE_ROLES.includes(member.role),
      );
    },
    eligibleCohortMembers(state) {
      const ELLIGIBLE_ROLES = ["student", "observer"];
      return state.programMembers.filter((member) =>
        ELLIGIBLE_ROLES.includes(member.role),
      );
    },
    allowMembersToSetInitialLevel(state, getters) {
      return getters.experienceConfiguration.initial_levelset;
    },
    hasBadges(state) {
      return state.programData.id
        ? state.programData.experience_configuration.has_badges
        : false;
    },
    hasCareers(state) {
      return state.programData.id
        ? state.programData.experience_configuration.has_careers
        : false;
    },
    hasLevelRequests(state) {
      return state.programData.id
        ? state.programData.experience_configuration.has_level_requests
        : false;
    },
  },
  mutations: {
    ...genericMixin.mutations,
    setProgramData(state, { programData }) {
      state.programData = programData;
    },
    setProgramList(state, { programList }) {
      state.programList = programList;
    },
    setUserData(state, { userData }) {
      state.programUserData = userData;
    },
    setUserList(state, { userList }) {
      state.programUsers = userList;
    },
    setMemberList(state, { data }) {
      state.programMembers = data;
    },
    setProgramWelcome(state, { welcome }) {
      state.programWelcome = welcome;
    },
    addNewProgram(state, newProgramData) {
      state.programList.push(newProgramData);
    },
    updateProgramList(state, activeProgramData) {
      state.programList = state.programList.map((existingProgram) => {
        return existingProgram.id === activeProgramData.id
          ? activeProgramData
          : existingProgram;
      });
    },
    setNotification(state, { text, status = "success" }) {
      state.notification.text = text;
      state.notification.status = status;
    },
    clearNotification(state) {
      state.notification.text = "";
      state.notification.status = "success";
    },
  },
  actions: {
    ...genericMixin.actions,
    fetchNewProgramData() {
      return axios
        .get(programCreateUrl.stringify())
        .then((response) => response.data);
    },
    loadProgramsList({ commit }) {
      return axios
        .get(programListUrl.stringify())
        .then((response) => {
          commit("setProgramList", { programList: response.data });
        })
        .catch((e) => {
          console.error(e);
        });
    },
    fetchProgramData(context, { programId }) {
      let url = programDataUrl.stringify({ programId });
      return axios.get(url);
    },
    loadProgramData({ state, commit, dispatch }) {
      let programId = state.programId;
      return dispatch("fetchProgramData", { programId })
        .then((response) => {
          commit("setProgramData", { programData: response.data });
        })
        .catch((e) => {
          console.error(e);
        });
    },
    async saveProgram({ dispatch, commit }, programData) {
      const response = await dispatch(
        programData.id ? "updateProgram" : "createProgram",
        programData,
      );
      if (response.status === "success") {
        commit("setProgramData", { programData: response.instance });
      }
      return response;
    },
    createProgram(context, programData) {
      let url = programCreateUrl.stringify();
      return axios.post(url, programData).then((response) => response.data);
    },
    updateProgram(context, programData) {
      let url = programUpdateUrl.stringify({ programId: programData.id });
      return axios.put(url, programData).then((response) => response.data);
    },
    deleteProgram(context, programData) {
      let url = programUpdateUrl.stringify({ programId: programData.id });
      return axios.delete(url, programData).then((response) => response.data);
    },
    attachProgramImage(context, imageData) {
      let url = programUploadImagesUrl.stringify({
        programId: imageData.programId,
      });
      let formData = new FormData();
      for (const [key, value] of Object.entries(imageData)) {
        formData.append(key, value);
      }
      return axios.post(url, formData).then((response) => response.data);
    },
    removeProgramImage(context, imageData) {
      let url = programRemoveImagesUrl.stringify({
        programId: imageData.programId,
      });
      return axios
        .delete(url, { data: imageData })
        .then((response) => response.data);
    },
    loadUsersList({ state, commit }) {
      return axios
        .get(programUsersListUrl.stringify({ programId: state.programId }))
        .then((response) => {
          commit("setUserList", { userList: response.data });
        });
    },
    loadMembersList: debounce(function ({ dispatch }, params) {
      return dispatch("loadObjectList", {
        urls: programMemberUrls,
        mutation: "setMemberList",
        params,
      });
    }, DEBOUNCE_MILLIS),
    async loadUnpaginatedMembersList({ getters, commit }, params) {
      let url = programMembersUnpaginatedSearchUrl.stringify({
        programId: getters.programId,
      });
      return await axios.get(url, { params }).then((members) => {
        commit("setMemberList", members);
      });
    },
    fetchNewUserData({ dispatch }) {
      return dispatch("fetchNewInstanceData", {
        createUrl: programUsersCreateUrl,
      });
    },
    fetchUserData({ state }, { userId }) {
      return axios.get(
        programUsersUpdateUrl.stringify({
          programId: state.programId,
          userId: userId,
        }),
      );
    },
    loadUserData({ getters, commit, dispatch }) {
      let userId = getters.userId;
      return dispatch("fetchUserData", { userId }).then((response) => {
        commit("setUserData", { userData: response.data });
      });
    },
    saveUser({ dispatch }, data) {
      return dispatch(data.id ? "updateUser" : "createUser", data);
    },
    createUser({ state }, data) {
      let url = programUsersCreateUrl.stringify({ programId: state.programId });
      return axios.post(url, data).then((response) => response.data);
    },
    updateUser({ state, getters }, data) {
      let url = programUsersUpdateUrl.stringify({
        programId: state.programData.id,
        userId: getters.userId || data.id,
      });
      return axios.put(url, data).then((response) => response.data);
    },
    deleteUser({ state, getters }, data) {
      let url = programUsersUpdateUrl.stringify({
        programId: state.programData.id,
        userId: getters.userId || data.id,
      });
      return axios.delete(url, data).then((response) => response.data);
    },
    loadPlaceholderData({ commit }) {
      commit("competency/setCompetencyList", {
        competencyList: placeholderData.programCompetencies,
      });
      commit("setProgramWelcome", { welcome: placeholderData.welcome });
      commit("competency/setProgramExercises", {
        exercises: placeholderData.exercises,
      });
    },
    errorNotification({ commit }, text) {
      commit("setNotification", { text, status: "error" });
    },
    successNotification({ commit }, text) {
      commit("setNotification", { text });
    },
  },
});
